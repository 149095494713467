import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Paper } from "@mui/material";

const CustomPaper = (props) => {
    return (
        <Paper
            {...props}
            elevation={5}
            sx={(theme) => ({
                fontSize: "0.8rem !important",
                lineHeight: "1rem !important",
                [theme.breakpoints.down("sm")]: {
                    lineHeight: "0.1rem",
                },
            })}
        />
    );
};

export default function FormMultipleSearchInput({
    label,
    placeholder = null,
    options = [],
    optionLabel = null,
    state,
    setState,
    setMoreOption = () => {},
    removeAll = true,
    group = false,
    groupByOption = null,
    disable = false,
}) {
    console.log(options, optionLabel)
    return (
        <Autocomplete
            multiple
            limitTags={1}
            id="multiple-limit-tags"
            options={options}
            getOptionLabel={
                optionLabel === null
                    ? (option) => option
                    : (option) => option[optionLabel]
            }
            groupBy={group ? (option) => option[groupByOption] : null}
            sx={(theme) => ({
                width: 400,
                [theme.breakpoints.down("sm")]: {
                    width: "calc(100vw - 3rem)",
                },
                "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                    },
                },
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        border: "2px solid #27606C",
                    },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                        borderColor: "#27606C",
                        "& > legend": {
                            color: "#27606C",
                        },
                    },
                },
                "& .MuiSvgIcon-root": {
                    color: `${disable ? "grey" : "#27606C"}`,
                },
                "& .MuiChip-deleteIcon.MuiSvgIcon-root": {
                    color: "gray",
                    "& :hover": {
                        color: "#27606C",
                    },
                },
            })}
            PaperComponent={CustomPaper}
            ListboxProps={{
                style: { maxHeight: "10rem" },
                position: "bottom-start",
            }}
            disabled={disable}
            value={state}
            onChange={(e, newValue) => {
                setState(newValue);

                if (optionLabel === null) {
                    const needToUpdate = state.filter(
                        (each) => each === "All"
                    ).length;

                    if (needToUpdate > 0) {
                        setState((prevState) =>
                            prevState.filter((each) => each !== "All")
                        );
                    }
                } else {
                    if (removeAll) {
                        const needToUpdate = state.filter(
                            (each) => each[optionLabel] === "All"
                        ).length;

                        if (needToUpdate > 0) {
                            setState((prevState) =>
                                prevState.filter(
                                    (each) => each[optionLabel] !== "All"
                                )
                            );
                        }
                    }
                }

                setMoreOption(false);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={label}
                    placeholder={placeholder}
                    InputLabelProps={{
                        style: { color: `${disable ? "grey" : "#27606C"}` },
                    }}
                />
            )}
        />
    );
}
