import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReadableDateTime } from '../../helpers/DateTime'
import { makeShorter } from '../../helpers/Text'

export default function EachAlert({ data }) {
	console.log(data);
	let icon =  (data.category) ? (data.category === 'news') ? 'news-icon.png'
						: (data.category === 'adv') ? 'advertisement.png' : 'custom.png' : 'none.png'

    const alert = {
        img: icon,
        bg: (data.open_at) ? ( data.open_at === 0) ? 'bg-white-m' : 'bg-custom' : 'bg-custom',
        created_at: ReadableDateTime(data.created_at),
        sorted_desc: makeShorter(data.desc, 64)
    }
    const alert_details_url = (data.type === 2) ? '/portal/json-alert-details' : '/portal/alert-details'
	
	return (
		<>
			<div className="alert-box row border-bottom">
				<div className="col-md-9 col-sm-12">
					<div className="row">
						<div className="col-1">
							<span className="alerts-icon">
								{ data.category === 'news' && <span className="news-badge">News</span> }
								{ data.category === 'adv' && <span className="ad-badge">Ads</span> }
								{ (data.alert_flag === 'customAlert' || data.alert_flag === 'transferAlert') ? <span className="file-badge">File</span> : "" }
							</span>
						</div>
						
						<div className="col-1 sm-new-badge">
							<span className="alerts-icon">
								{(data.open_at === 0 || data.unread === 1) ? (
									<span className="new-badge">new</span>
								): ''}
							</span>
						</div>

						<div className="col-9">
							
						{ data.alert_flag === 'transferAlert' ? (
								<NavLink 
									to={`${alert_details_url}/${data._id}`}
									style={{ textDecoration: 'none', color: 'black' }}
									className="alerts-title" 
								>
									<span>
										{(data.name) ? data.name : data.title}
									</span>
								</NavLink>
							) : 
							data.doc_id && data.alert_flag !== 'customAlert'  ?
							(
								<NavLink 
									to={`${alert_details_url}/${data.doc_id}`}
									style={{ textDecoration: 'none', color: 'black' }}
									className="alerts-title" 
								>
									<span>{data.title}</span>
								</NavLink>
							) : (
								<NavLink 
									to={`${alert_details_url}/${data._id}`}
									style={{ textDecoration: 'none', color: 'black' }}
									className="alerts-title" 
								>
									<span>
										{(data.name) ? data.name : data.title}
									</span>
								</NavLink>
							)
						}
						</div>
					</div>
				</div>
				<div className="col-md-3 alerts-time">
					<small>{alert.created_at}</small>
				</div>
			</div>
		</>
	)
}
