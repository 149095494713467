/* eslint-disable */

import React from "react"
import { useParams } from "react-router-dom"
import DailyNewsClassic from "./classic/DailyNewsClassic"
import DailyAdvClassic from "./classic/DailyAdvClassic"
import PeriodicalAdvElasticClassic from "./classic/PeriodicalAdvElasticClassic"
import {setStore, getStore} from "../../helpers/Storage"

const fetched = (id) => {
    let dt = getStore('alert_service')
    let alert_arr = dt.value
    let type = ''
    let info = alert_arr.filter((value) => {
        // find out, alert type
        if(value.alert_flag){
            if (value._id === id || value.doc_id === id) {
                // if open_at is missing or zero its mean its unread or new alert
                if(value.open_at === 'undefined' || value.open_at === 0) {
                    console.log(value.open_at)
                }
                return value.alert_flag
            }
        }
    })
    if(info){
        type = info[0].alert_flag
    }
    return type
}

const changeNewStatus = (_id) => {
    // fetch storage alert service data if exists
    const alertService = getStore('alert_service') || []
    let dt = {}

    // if alert service found
    if(alertService){
        // get alert service data as array
        let service_data = alertService.value
        for(let i=0; i < service_data.length; i++){
            if(service_data[i]._id === _id || service_data[i].doc_id === _id){
                dt = service_data[i]
                // update alert new property
                service_data[i].new = 0
                break
            }
        }
        // re-store alert service data
        setStore('alert_service', service_data)
    }
}

function JSONAlertServiceDetailsPage(){
    
    // init alert type with empty
    let [alertType, setAlertType] = React.useState('')

    // get alert service id
    let { id } = useParams()

    let renderSwitch = (param) => {
        switch(param){
            case 'dailyAdvertise': 
                return <DailyNewsClassic doc_id={id} />
            case 'dailyNews':
                return <DailyNewsClassic doc_id={id} />
            case 'periodicNews':
                return <DailyNewsClassic doc_id={id} />
            case 'dailyAdv':
                return <DailyAdvClassic doc_id={id} />
            case 'periodicAdv':
                return <DailyAdvClassic doc_id={id} />
            case 'periodicAdvElastic':
                return <PeriodicalAdvElasticClassic doc_id={id} />
            default:
                return 'Sorry could not find the alert type ... 😥😥😥 !!!!'
        }
    }
    
    React.useEffect( () => {
        // get selected alert type
        setAlertType(fetched(id))
        changeNewStatus(id)
    },[])

    return(
        <>  
            {/* alert details header */}
            <div style={{minHeight:90+'vh'}}>
                <div className="top-bar bg-prim-2">
                    <div className="container">
                        <div id="overlay"></div>
                    </div>
                </div>

                {/* alert details  */}
                {renderSwitch(alertType)}
            </div>
        </>
    )
}

export default JSONAlertServiceDetailsPage
