/* eslint-disable */

import React from "react"
import {NavLink} from "react-router-dom"
import DatePicker from "react-datepicker"
import { removeCookies } from "../../helpers/Cookies"
import { removeStore } from "../../helpers/Storage"
import "react-datepicker/dist/react-datepicker.css"
import {
    getAlertServiceFlashData,
    getJSONAlertService,
    getAlertService,
    getUnreadAlert,
    getAlertInbox,
} from "../../api/request/AlertServiceAPI"
import {setStore, getStore} from "../../helpers/Storage"
import LoadingOverlay from 'react-loading-overlay'
import EachAlert from "../components/EachAlert"

function RequestServiceComponent(){

	const hide = {display:'none'}
	const block = {display:'block'}
	
    document.title = "Alert Lists"

	// state for, all alert
    
    const [alert, setAlert] = React.useState([])
	// state for, all alert
	const [prevAlert, setPrevAlert] = React.useState([])
	
	// state for page loading indicator
	let [loading, setLoading] = React.useState(false)
	
	// state for, search by date 
	const [startDate, setStartDate] = React.useState(new Date())
	
	// state for, search by name
	const [searchName, setSearchName] = React.useState('')
	
	// state for, checked option	
	const [checkedBtn, setCheckedBtn] = React.useState('name')

    // size for total number of alert
    const [size, setSize] = React.useState(0)
    
    // what number is now displaying
    const [display, setDisplay] = React.useState("1-15")

    // current page number
    const [currentPage, setCurrentPage] = React.useState(1)

    // index of start pagination
	const [index, setIndex] = React.useState(0)

    // client logout 
    const logOut = (e) => {
        // clear client cookies
        removeCookies('udata', {path:"/"});
        // clear client storage data
        removeStore('alert_service');
    }

	// executed when search option will be changed.
	const handleChange = (event) => {
		setCheckedBtn(event.target.value)
	}
	
	// search alert by alert name 
	const typedAlertName = (event) => {
		// set filter search name 
		setSearchName(event.target.value.toLowerCase())

        let dt = getStore('alert_service') || []
        let all_alert = (dt.value) ? dt.value : []       

		// find filterd data
		let filterData = Object.keys(all_alert)
		.filter(function(key){
            if (all_alert[key].name) {
                return key && all_alert[key].name.toLowerCase().indexOf(searchName) >=0
            } else if (all_alert[key].title) {
                return key && all_alert[key].title.toLowerCase().indexOf(searchName) >=0
            } else {
                return false
            }
		})
		.map(function(key){
			return all_alert[key]
		})

		// set filter / search data to the alert object
		setAlert(filterData)
		
		// reset alert data 
		if(event.target.value.length === 0){
			// reset with existing alert
            displayData('START')
		}
	}

    // search alert by alert date
    const searchAlertByDate = (date) => {
        // set selected date
        setStartDate(date)
        // get string date format from date timestamp # Ex, Thu Jan 20 2022 15:21:58 GMT+0600 (Bangladesh Standard Time) 
        const date_timestamp = new Date(Date.parse(date))
        // get only date from the string date format # Ex, '1/20/2022'
        const date_format = date_timestamp.toLocaleDateString("en-US")
        // selected date unix format # Ex, 1642615200
        const select_date_unix = Date.parse(date_format)/1000
        // update selected date unix with one day: Ex, 1642701599
        const select_date_add_one = select_date_unix+86399

        let dt = getStore('alert_service') || []
        let all_alert = (dt.value) ? dt.value : []
		// find filterd data
		let filterData = Object.keys(all_alert)
		.filter(function(key){
            console.log(select_date_unix, all_alert[key].created_at, select_date_add_one)
            if (all_alert[key].created_at >= select_date_unix && 
                all_alert[key].created_at <= select_date_add_one) {
                return key
            }
		})
		.map(function(key){
			return all_alert[key]
		})

		// set filter / search data to the alert object
		setAlert(filterData)
    }

    // fetched stored alert
    const fetchStoreData = () => {
        // fetch alert service data from storage if exists
        const alertService = getStore('alert_service') || []
        return alertService
    }

    // pagination 
    const paginate = (action) => {
        if (action === 'next') {
            displayData('NEXT')
        } else if (action === 'prev') {
            displayData('PREV')
        }
    }

    // display alert list of verious format
    const displayData = (opt) => {
        const data_arr = fetchStoreData()
        let data = data_arr.value.filter(each => each.type !== 4) // without others alert files
        if(opt === 'START') 
        { 
            setAlert((data) ? data.slice(0, 15) : [])  // start with first 15 alert service data
            //setAlert(data.slice(0, 15))  
        } 
        else if(opt === 'NEXT') 
        {
            let start = index + 15
            let end = start + 15
            if (size > start) {
                setStore('paginate', start)
                setIndex(index => index + 15)
                setDisplay(`${start+1}-${end}`)
                setAlert(data.slice(start, end))  // update with next alert service data
            }
        }
        else if(opt === 'PREV')
        {
            let start = index - 15
            let end = start + 15
            if (start >= 0) {
                setStore('paginate', start)
                setIndex(index => index - 15)
                setDisplay(`${start+1}-${end}`) // text for number of displaying alert now
                setAlert(data.slice(start, end))  // update with previous alert service data
            }
        }

        /**
         * set total inboxed alert size
         */
        (data) ? setSize(data.length): setSize(0)
        
    }
	
    React.useEffect( () => {
        // fetch alert service data from alert inbox
        const fetchData = async () => {
            // Fetch alert services data from api call
            let data = await getAlertService()

            // Fetch alert inbox data
            let alert_inbox = await getAlertInbox()

            // if inboxed alert found
            if (alert_inbox.status == 1) {
                
                // get inbox alert array
                let alert = alert_inbox.alert
                // // get inbox alert array without other service type alerts (4)
                // let alert = alert_inbox.alert.filter(each => each.type !== 4)
                
                // reserve alert list and set inbox alert
                setStore('alert_service', alert.reverse())

                // start alert displaying
                displayData('START')
            }

            // get cached file size
            if( process.env.REACT_APP_STAGE === "Development" ){
                let size = Buffer.byteLength(JSON.stringify(alert_inbox))
                let kb = (size/1024).toFixed(2)
                let mb = (kb/1024).toFixed(2)
                console.log(` Data Size : ${kb} KB ~~~ ${mb} MB`)
            }
        }

        // call this alert service fetch method after 30 seconds
        const interval = setInterval( async () => {
            // fetch unread alert from the alert inbox document
            const fetch_unread_alert = await getUnreadAlert()
            
            // if unread alert found, then add to the existing list
            if (fetch_unread_alert.length > 0) {
                // fetch existing alert list
                let dt = getStore('alert_service')

                // get unread alert
                const list = fetch_unread_alert[0].unread_alert
                
                for(const values of list){
                    
                    let flag = true

                    for (const i of dt.value) {
                        if(i.alert_id == values.alert_id){
                            flag = false
                            break;
                        }
                    }

                    if(flag){
                        // set 1, express its a new alert
                        values['new'] = 1   
                        
                        // set 1, express its unopend/new alert
                        values['unread'] = 1    

                        if (dt.value !== null) {
                            // set fetching items/flash alert to the very first position of the list
                            dt.value.unshift(values)
                        } else {
                            dt.value = [values]
                        }   
                    }

                }

                // Reset alert service data to the storage
                setStore('alert_service', dt.value)

                // load list
                displayData('START')
            }
        }, 30000 );

        let alert_data = fetchStoreData()

        // set alert data if exists, if not then fetch alert data form server
        if (alert_data.length !== 0) {
            displayData('START')
        } else {
            // call this method very first time to fetch inbox alert.
            fetchData() // fetch alert data
        }

        return () => clearInterval(interval)
    },[])

    return(
        <>
            <LoadingOverlay active={loading} spinner text='Fetching Data ... ...' >
                    
                <div className="content-section">

                <div className="body-section py-2">
                        <div className="alert-service-container">
                        <div className="row mb-1">
                                <div className="col-md-4">
                                    
                                    <div className="search">
                                        <div className="search-title">
                                            <div className="search-option btn-prim-2 font-white p-y-6 p-xl-10">
                                                <strong>
                                                    <u>Search</u>
                                                </strong>
                                                <div className="form-check form-check-inline padding-l-two-rem">
                                                    <input
                                                        className="form-check-input"
														type="radio" id="inlineCheckbox2"
														checked={checkedBtn === 'name' ? true : false }
														value="name" name="search-type" onChange={handleChange} />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">
                                                        By Alert Name
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline padding-l-two-rem ">
                                                    <input
                                                        className="form-check-input"
														type="radio" id="inlineCheckbox1"
														checked={checkedBtn === 'date' ? true : false }
														value="date" name="search-type" onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                                                        By Date
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6 sm-mt-1">
                                    <div className="row">
                                        <div className="col-8">

                                            <div className="search-box" style={checkedBtn === 'date' ? block : hide}>
                                                <DatePicker
                                                    className="form-control" 
                                                    selected={startDate} 
                                                    name="searchDate"
                                                    dateFormat="dd-MMM-yyyy"
                                                    onChange={(date)=>searchAlertByDate(date)}
                                                />
                                            </div>
                                            <div className="search-box" style={checkedBtn === 'name' ? block : hide}>
                                                <input 
                                                    className="form-control"
                                                    name="searchName"
                                                    type="text"
                                                    placeholder="Write alert name here..."
                                                    onChange={typedAlertName} 
                                                />									
                                            </div>

                                        </div>
                                        <div className="col-4">

                                            <div className="search-box">
                                                <input
                                                    className="form-control bg-prim-2 text-white"
                                                    type="submit"
                                                    value="Refresh" 
                                                    onClick={() => window.location.reload()}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 pt-1 text-center">
                                    
                                    <NavLink 
                                        to="#" 
                                        onClick={() => {paginate('prev')}} 
                                    >
                                        <button className="paginate-btn">&lt;</button>
                                    </NavLink>
                                    <span style={{fontWeight: 'bold', fontSize: '1rem', color: '#27606C'}}>{display} of {size}</span>
                                    <NavLink 
                                        to="#" 
                                        onClick={() => {paginate('next')}} 
                                    >
                                        <button className="paginate-btn">&gt;</button>
                                    </NavLink>

                                </div>
                            </div>

                            {/* Alert Service Component, Display Each Row of alert */}
                            { (alert) ? alert.map(function(value, index){
                                return <EachAlert data={value} key={value._id} />
                            }) : 
                            (
                                <div className="col-md-15 text-center">
                                    <img 
                                        src={window.location.origin+"/assets/images/dashboard/inbox.jpg"} 
                                        style={{width:"25%"}}
                                        alt="" 
                                        className="img-fluid"
                                    />
                                    <h4 className="text-center text-danger">Empty Alert Inbox</h4>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            
            </LoadingOverlay>
        </>
    )
}

export default RequestServiceComponent;
